import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import bimalama from "../../Assets/Projects/bimalama.JPG";
import bimareborn from "../../Assets/Projects/bimareborn.JPG";
import bimasakti from "../../Assets/Projects/bimasakti.JPG";
import ppdb from "../../Assets/Projects/ppdb.JPG";
import ppdbyayasan from "../../Assets/Projects/ppdbyayasan.JPG";
import absensi from "../../Assets/Projects/absensi.JPG";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bimalama}
              isBlog={false}
              title="BIMA v1"
              description="BIMA v1 - Sistem Pendataan Madrasah Kab. Malang"
              ghLink="https://github.com/"
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bimareborn}
              isBlog={false}
              title="BIMA REBORN"
              description="BIMA REBORN - Sistem Pendataan Madrasah Kab. Malang"
              ghLink="https://github.com/"
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bimasakti}
              isBlog={false}
              title="BIMA SAKTI"
              description="BIMA SAKTI - Sistem Pendataan Madrasah Kab. Malang"
              ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://editor.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ppdb}
              isBlog={false}
              title="PPDB BIMA"
              description="PPDB BIMA - Aplikasi PPDB Madrasah Kab. Malang"
              ghLink="https://github.com"
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ppdbyayasan}
              isBlog={false}
              title="PPDB YAYASAN"
              description="Pengembangan dari PPDB BIMA, dapat digunakan multi jenjang (dalam satu yayasan)"
              ghLink="https://github.com"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={absensi}
              isBlog={false}
              title="Absensi Fingerprint, Face Recognition, RFID"
              description="Integrasi Mesin Absensi Fingerprint, Face Recognition, RFID dengan aplikasi"
              ghLink="https://github.com/"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
